<template>
  <section>
    <NewCapacityType :isOpen.sync="showCapacityTypeModal" @save="newCapacityTypeKey++" />
    <NewGarage :isOpen.sync="showGarageModal" @save="newGarageKey++" />
    <NewVehicleGroup :isOpen.sync="showVehicleGroupModal" @save="newVehicleGroupKey++" />
    <Card :loading="loading.get">
      <template #header>
        <StepCardHeader :steps="[{ name: 'global.general' }]" returnRoute="/data/vehicles" />
      </template>
      <div id="vehicles-general" class="flex">
        <div class="card-form flex">
          <div class="photo-container">
            <PicturePicker
              ref="picturePicker"
              :preasigned="{
                apiUrl: '/presigned/vehicle',
                fileName: `${vehicle.id}.png`
              }"
              :alt="vehicle.name"
              :pickMode="vehicle.id ? false : true"
            />
          </div>
          <form ref="form" @submit.prevent="onSubmit">
            <div class="sub-title">{{ $t('vehicle.sectionTitle') }}</div>
            <b-field grouped>
              <Field
                :label="$t('vehicle.name')"
                class="w-33"
                v-model="vehicle.name"
                :maxlength="60"
                required
                :placeholder="`${$t('global.example')}: BUS 10`"
              />
              <Autocomplete
                :key="newCapacityTypeKey"
                class="w-33"
                :api="{
                  url: 'vehicle_capacity_types',
                  full: true,
                  params: [{ id: 'is_active', value: 1 }]
                }"
                :label="$t('vehicle.capacity')"
                :value="vehicle['vehicle_capacity_type.name']"
                @add="
                  () => {
                    showCapacityTypeModal = true;
                  }
                "
                @select="
                  (data) => {
                    vehicle.vehicle_capacity_type_id = data.id;
                  }
                "
                field="name"
                :permission="Permission.capacity.create"
                required
              />
              <Field :label="$t('vehicle.phoneLogin')" class="w-33">
                <QRDownloader
                  :key="`qr_${qrKey}`"
                  :text="vehicle.smartphone_login || smartphone_login"
                  :name="`QR_login_${vehicle.name}`"
                  :disabled="!vehicle.id"
                  fullWidth
                />
              </Field>
            </b-field>
            <b-field grouped>
              <Field
                label="VIN"
                class="w-33"
                v-model="vehicle.vin"
                maxlength="17"
                minlength="17"
                :pattern="vinRegex"
                :placeholder="`${$t('global.example')}: 1HGBH41JXMN109186`"
                :validation-message="$t('validations.VIN')"
                required
              />
              <Field
                :label="$t('vehicle.license')"
                class="w-33"
                v-model="vehicle.license_plate_number"
                :placeholder="$t('validations.plates')"
                :pattern="licensePlateRegex"
                :validation-message="$t('validations.plates')"
                maxlength="7"
                minlength="7"
                required
              />
              <Autocomplete
                :key="newVehicleGroupKey"
                :label="$t('vehicle.group')"
                :api="{
                  url: 'vehicle_groups',
                  full: true,
                  params: [{ id: 'is_active', value: 1 }]
                }"
                :value="vehicle[['vehicle_group.name']]"
                class="w-33"
                @select="
                  (data) => {
                    vehicle.vehicle_group_id = data.id;
                  }
                "
                field="name"
                @add="
                  () => {
                    showVehicleGroupModal = true;
                  }
                "
                :permission="Permission.group.create"
                required
              />
            </b-field>
            <b-field grouped>
              <Autocomplete
                :label="$t('vehicle.make')"
                v-model="vehicle.make"
                class="w-33"
                field="label"
                model="label"
                :data="cardbrands"
              />
              <!-- // TODO Autocomplete back requiered -->
              <Autocomplete
                :label="$t('vehicle.model')"
                :api="{
                  url: 'catalogs/vehicle_models',
                  full: true
                }"
                v-model="vehicle.model"
                class="w-33"
                model="name"
                field="name"
                required
              />
              <Autocomplete
                :label="$t('vehicle.year')"
                v-model="vehicle.year"
                class="w-16"
                field="label"
                model="id"
                :data="years"
                maxlength="4"
                :has-counter="false"
                required
              />
              <Autocomplete
                label="Color"
                v-model="vehicle.color"
                class="w-16"
                field="label"
                model="id"
                :data="colors"
              />
            </b-field>
            <b-field grouped>
              <Autocomplete
                :key="newGarageKey"
                :label="$t('vehicle.garage')"
                :api="{
                  url: 'vehicle_garages',
                  full: true,
                  params: [{ id: 'is_active', value: 1 }]
                }"
                class="w-66"
                v-model="vehicle.vehicle_garage_id"
                model="id"
                field="full_address"
                @add="
                  () => {
                    showGarageModal = true;
                  }
                "
                :permission="Permission.garage.create"
                required
              />
            </b-field>
            <b-field grouped>
              <Field
                label="IMEI"
                class="w-33"
                v-model="vehicle.imei"
                :placeholder="`${$t('global.example')}: 357812097680604`"
                :pattern="imeiRegex"
                maxlength="16"
                minlength="15"
                required
              />
              <Field
                label="MTS"
                class="w-33"
                v-model="vehicle.mts_number"
                :placeholder="`${$t('global.example')}: N-12345`"
                :pattern="mtsRegex"
                maxlength="7"
                mask="N-11111"
                placeholder-char="0"
              />
            </b-field>
            <b-button
              class="submit-btn"
              type="is-primary"
              :label="$t('button.save')"
              native-type="submit"
              :loading="loading.save"
            />
          </form>
        </div>
      </div>
    </Card>
  </section>
</template>

<script>
import { toast } from '@/utils/dialog';
import Autocomplete from '@/components/Autocomplete.vue';
import Card from '@/components/Card.vue';
import cardbrands from './json/carbrands.json';
import Field from '@/components/Field.vue';
import NewCapacityType from '@/components/Vehicles/NewCapacityType.vue';
import NewGarage from '@/components/Vehicles/NewGarage.vue';
import NewVehicleGroup from '@/components/Vehicles/NewVehicleGroup.vue';
import PicturePicker from '@/components/PicturePicker.vue';
import QRDownloader from '@/components/QRDownloader.vue';
import StepCardHeader from '@/components/StepCardHeader';
import { Permissions } from '@/utils/Secure';
import { vinRegex, licensePlateRegex, mtsRegex, imeiRegex } from '@/utils/RegexValidations';

export default {
  components: {
    Autocomplete,
    Card,
    Field,
    NewCapacityType,
    NewGarage,
    NewVehicleGroup,
    PicturePicker,
    QRDownloader,
    StepCardHeader
  },
  async mounted() {
    this.$store.dispatch('setBreadcumbs', [
      'menu.dat',
      this.paramsId && !this.vehicle.name ? 'vehicle.edit' : 'vehicle.new'
    ]);
    await this.getVehicle();
    if (!this.vehicle.name && this.paramsId) {
      this.$router.push('/data/vehicles/add');
    }
    this.cardbrands.map((brand) => {
      brand.id = this.cardbrands.indexOf(brand);
    });
  },
  data() {
    const paramsId = Number(this.$route.params.vehicleId || 0);
    return {
      paramsId,
      qrKey: 1,
      loading: { get: false, save: false },
      vehicle: { name: '', id: paramsId },
      cardbrands,
      addNew: false,
      showCapacityTypeModal: false,
      showGarageModal: false,
      showVehicleGroupModal: false,
      newCapacityTypeKey: 1,
      newGarageKey: 1,
      newVehicleGroupKey: 1,
      vinRegex,
      licensePlateRegex,
      mtsRegex,
      imeiRegex
    };
  },
  computed: {
    smartphone_login() {
      return `${this.random(10000, 99999)}`;
    },
    years() {
      let yearsArray = [];
      let date = new Date().getFullYear() + 1;
      for (let i = 0; i < 22; i++) {
        yearsArray.push({ id: (date - i).toString(), label: (date - i).toString() });
      }
      return yearsArray;
    },
    colors() {
      return [
        { id: 'BLACK', label: this.$t('vehicle.colors.black') },
        { id: 'BLUE', label: this.$t('vehicle.colors.blue') },
        { id: 'GRAY', label: this.$t('vehicle.colors.gray') },
        { id: 'GREEN', label: this.$t('vehicle.colors.green') },
        { id: 'RED', label: this.$t('vehicle.colors.red') },
        { id: 'WHITE', label: this.$t('vehicle.colors.white') },
        { id: 'YELLOW', label: this.$t('vehicle.colors.yellow') }
      ];
    },
    Permission() {
      const {
        VehiclesCapacityType: capacity,
        VehiclesGarage: garage,
        VehiclesGroup: group
      } = Permissions;
      return { capacity, garage, group };
    }
  },
  methods: {
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    prepareData() {
      const { vehicle: V, smartphone_login } = this;
      return {
        name: V.name,
        license_plate_number: V.license_plate_number,
        vin: V.vin,
        color: V.color,
        vehicle_capacity_type_id: V.vehicle_capacity_type_id,
        vehicle_group_id: V.vehicle_group_id,
        smartphone_login: V.smartphone_login || smartphone_login,
        make: V.make,
        year: V.year,
        model: V.model,
        imei: V.imei,
        vehicle_garage_id: V.vehicle_garage_id,
        mts_number: V.mts_number
      };
    },
    async onSubmit() {
      this.loading.save = true;
      try {
        const { data } = await this.saveVehicle();
        this.qrKey++;
        this.vehicle.id = data.id;
        await this.savePicture();
        toast('success', this.$t('messages.saved'), 5000);
        this.$emit('change', this.vehicle);
        this.addNew = true;
        this.$router.replace({
          path: `/data/vehicles/${this.vehicle.id}`
        });
      } catch (error) {
        console.error(error);
      }
      this.loading.save = false;
    },
    async saveVehicle() {
      const { id } = this.vehicle;
      const pData = this.prepareData();
      const method = id ? 'put' : 'post';
      const url = `/vehicles${id ? `/${id}` : ''}`;
      return await this.Api[method](url, pData);
    },
    async savePicture() {
      const fileName = `${this.vehicle.id}.png`;
      await this.$refs.picturePicker.save({ fileName });
    },
    async getVehicle() {
      if (this.paramsId) {
        this.loading.get = true;
        try {
          const { data } = await this.Api.get(`vehicles/${this.paramsId}`);
          this.vehicle = data;
          this.vehicle.year = this.vehicle.year.toString();
        } catch (error) {
          console.error(error);
        }
        this.loading.get = false;
      }
    },
    reloadPage() {
      window.location.reload();
    },
    goBack() {
      // TODO validate history to stay
      this.$router.go(-1);
    }
  },
  watch: {
    '$route.path'(value) {
      if (value === '/data/vehicles/add')
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'vehicle.new']);
      else {
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'vehicle.edit']);
      }
    }
  }
};
</script>
<style lang="sass" scoped>
.card
  margin: 0
  min-height: calc(100% - 90px)
  ::v-deep
    .card-header
      padding: 0
      justify-content: flex-end
#vehicles-general
  .card-form
    margin: 0 auto
    width: 100%
    max-width: 1200px
    .picturePicker
      margin-right: 10px
      width: 200px
      height: 200px
      margin-bottom: 20px
      @media screen and ( max-width: $bp-xxl )
        width: 120px
        height: 120px
    form
      width: 100%
      margin-left: 10px
      .sub-title
        font-size: $f-xl
        font-weight: bold
        margin-bottom: 50px
      .field
        margin-bottom: 10px
      .submit-btn
        float: right
        margin: 30px 10px
        width: 120px
      .add-btn
        margin-top: 30px
.w-16
  width: calc(16.5% - 0.75rem)
.w-33
  width: calc(33% - 0.75rem)
.w-40
  width: calc(40% - 0.75rem)
.w-50
  width: calc(49.5% - 0.75rem)
.w-66
  width: calc(66% - 0.75rem)
.w-82
  width: calc(82.5% - 0.75rem)
</style>
