var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('NewCapacityType',{attrs:{"isOpen":_vm.showCapacityTypeModal},on:{"update:isOpen":function($event){_vm.showCapacityTypeModal=$event},"update:is-open":function($event){_vm.showCapacityTypeModal=$event},"save":function($event){_vm.newCapacityTypeKey++}}}),_c('NewGarage',{attrs:{"isOpen":_vm.showGarageModal},on:{"update:isOpen":function($event){_vm.showGarageModal=$event},"update:is-open":function($event){_vm.showGarageModal=$event},"save":function($event){_vm.newGarageKey++}}}),_c('NewVehicleGroup',{attrs:{"isOpen":_vm.showVehicleGroupModal},on:{"update:isOpen":function($event){_vm.showVehicleGroupModal=$event},"update:is-open":function($event){_vm.showVehicleGroupModal=$event},"save":function($event){_vm.newVehicleGroupKey++}}}),_c('Card',{attrs:{"loading":_vm.loading.get},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('StepCardHeader',{attrs:{"steps":[{ name: 'global.general' }],"returnRoute":"/data/vehicles"}})]},proxy:true}])},[_c('div',{staticClass:"flex",attrs:{"id":"vehicles-general"}},[_c('div',{staticClass:"card-form flex"},[_c('div',{staticClass:"photo-container"},[_c('PicturePicker',{ref:"picturePicker",attrs:{"preasigned":{
              apiUrl: '/presigned/vehicle',
              fileName: ((_vm.vehicle.id) + ".png")
            },"alt":_vm.vehicle.name,"pickMode":_vm.vehicle.id ? false : true}})],1),_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.$t('vehicle.sectionTitle')))]),_c('b-field',{attrs:{"grouped":""}},[_c('Field',{staticClass:"w-33",attrs:{"label":_vm.$t('vehicle.name'),"maxlength":60,"required":"","placeholder":((_vm.$t('global.example')) + ": BUS 10")},model:{value:(_vm.vehicle.name),callback:function ($$v) {_vm.$set(_vm.vehicle, "name", $$v)},expression:"vehicle.name"}}),_c('Autocomplete',{key:_vm.newCapacityTypeKey,staticClass:"w-33",attrs:{"api":{
                url: 'vehicle_capacity_types',
                full: true,
                params: [{ id: 'is_active', value: 1 }]
              },"label":_vm.$t('vehicle.capacity'),"value":_vm.vehicle['vehicle_capacity_type.name'],"field":"name","permission":_vm.Permission.capacity.create,"required":""},on:{"add":function () {
                  _vm.showCapacityTypeModal = true;
                },"select":function (data) {
                  _vm.vehicle.vehicle_capacity_type_id = data.id;
                }}}),_c('Field',{staticClass:"w-33",attrs:{"label":_vm.$t('vehicle.phoneLogin')}},[_c('QRDownloader',{key:("qr_" + _vm.qrKey),attrs:{"text":_vm.vehicle.smartphone_login || _vm.smartphone_login,"name":("QR_login_" + (_vm.vehicle.name)),"disabled":!_vm.vehicle.id,"fullWidth":""}})],1)],1),_c('b-field',{attrs:{"grouped":""}},[_c('Field',{staticClass:"w-33",attrs:{"label":"VIN","maxlength":"17","minlength":"17","pattern":_vm.vinRegex,"placeholder":((_vm.$t('global.example')) + ": 1HGBH41JXMN109186"),"validation-message":_vm.$t('validations.VIN'),"required":""},model:{value:(_vm.vehicle.vin),callback:function ($$v) {_vm.$set(_vm.vehicle, "vin", $$v)},expression:"vehicle.vin"}}),_c('Field',{staticClass:"w-33",attrs:{"label":_vm.$t('vehicle.license'),"placeholder":_vm.$t('validations.plates'),"pattern":_vm.licensePlateRegex,"validation-message":_vm.$t('validations.plates'),"maxlength":"7","minlength":"7","required":""},model:{value:(_vm.vehicle.license_plate_number),callback:function ($$v) {_vm.$set(_vm.vehicle, "license_plate_number", $$v)},expression:"vehicle.license_plate_number"}}),_c('Autocomplete',{key:_vm.newVehicleGroupKey,staticClass:"w-33",attrs:{"label":_vm.$t('vehicle.group'),"api":{
                url: 'vehicle_groups',
                full: true,
                params: [{ id: 'is_active', value: 1 }]
              },"value":_vm.vehicle[['vehicle_group.name']],"field":"name","permission":_vm.Permission.group.create,"required":""},on:{"select":function (data) {
                  _vm.vehicle.vehicle_group_id = data.id;
                },"add":function () {
                  _vm.showVehicleGroupModal = true;
                }}})],1),_c('b-field',{attrs:{"grouped":""}},[_c('Autocomplete',{staticClass:"w-33",attrs:{"label":_vm.$t('vehicle.make'),"field":"label","model":"label","data":_vm.cardbrands},model:{value:(_vm.vehicle.make),callback:function ($$v) {_vm.$set(_vm.vehicle, "make", $$v)},expression:"vehicle.make"}}),_c('Autocomplete',{staticClass:"w-33",attrs:{"label":_vm.$t('vehicle.model'),"api":{
                url: 'catalogs/vehicle_models',
                full: true
              },"model":"name","field":"name","required":""},model:{value:(_vm.vehicle.model),callback:function ($$v) {_vm.$set(_vm.vehicle, "model", $$v)},expression:"vehicle.model"}}),_c('Autocomplete',{staticClass:"w-16",attrs:{"label":_vm.$t('vehicle.year'),"field":"label","model":"id","data":_vm.years,"maxlength":"4","has-counter":false,"required":""},model:{value:(_vm.vehicle.year),callback:function ($$v) {_vm.$set(_vm.vehicle, "year", $$v)},expression:"vehicle.year"}}),_c('Autocomplete',{staticClass:"w-16",attrs:{"label":"Color","field":"label","model":"id","data":_vm.colors},model:{value:(_vm.vehicle.color),callback:function ($$v) {_vm.$set(_vm.vehicle, "color", $$v)},expression:"vehicle.color"}})],1),_c('b-field',{attrs:{"grouped":""}},[_c('Autocomplete',{key:_vm.newGarageKey,staticClass:"w-66",attrs:{"label":_vm.$t('vehicle.garage'),"api":{
                url: 'vehicle_garages',
                full: true,
                params: [{ id: 'is_active', value: 1 }]
              },"model":"id","field":"full_address","permission":_vm.Permission.garage.create,"required":""},on:{"add":function () {
                  _vm.showGarageModal = true;
                }},model:{value:(_vm.vehicle.vehicle_garage_id),callback:function ($$v) {_vm.$set(_vm.vehicle, "vehicle_garage_id", $$v)},expression:"vehicle.vehicle_garage_id"}})],1),_c('b-field',{attrs:{"grouped":""}},[_c('Field',{staticClass:"w-33",attrs:{"label":"IMEI","placeholder":((_vm.$t('global.example')) + ": 357812097680604"),"pattern":_vm.imeiRegex,"maxlength":"16","minlength":"15","required":""},model:{value:(_vm.vehicle.imei),callback:function ($$v) {_vm.$set(_vm.vehicle, "imei", $$v)},expression:"vehicle.imei"}}),_c('Field',{staticClass:"w-33",attrs:{"label":"MTS","placeholder":((_vm.$t('global.example')) + ": N-12345"),"pattern":_vm.mtsRegex,"maxlength":"7","mask":"N-11111","placeholder-char":"0"},model:{value:(_vm.vehicle.mts_number),callback:function ($$v) {_vm.$set(_vm.vehicle, "mts_number", $$v)},expression:"vehicle.mts_number"}})],1),_c('b-button',{staticClass:"submit-btn",attrs:{"type":"is-primary","label":_vm.$t('button.save'),"native-type":"submit","loading":_vm.loading.save}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }