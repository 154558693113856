<template>
  <div id="vehicleGroup">
    <Modal
      :isOpen.sync="isModalOpen"
      :header="$t('modal.add', [$tc('vehicle.group')])"
      :loading="loading"
      allowOverflow
      @cancel="() => {}"
      @save="onSubmit"
    >
      <form ref="form" @submit.prevent="onSubmit">
        <Field
          :label="$t('modal.name')"
          v-model="vehicleGroup.name"
          :maxlength="60"
          :placeholder="$t('global.placeholder.enter', [$tc('global.name')])"
          required
        />
        <Field
          :label="$t('modal.description')"
          v-model="vehicleGroup.description"
          :maxlength="60"
          :placeholder="$t('global.placeholder.enter', [$tc('global.description')])"
          required
        />
        <ColorPicker
          :label="$t('vehicle.displayColor')"
          required
          v-model="vehicleGroup.display_color"
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Field from '@/components/Field.vue';
import ColorPicker from '@/components/ColorPicker.vue';
import { toast } from '@/utils/dialog';

export default {
  components: {
    Modal,
    Field,
    ColorPicker
  },
  data() {
    return {
      loading: { save: false, get: false },
      isModalOpen: false,
      vehicleGroup: {}
    };
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    }
  },
  methods: {
    async onSubmit() {
      if (this.validate()) {
        this.loading.save = true;
        try {
          const pData = this.prepareData();
          await this.Api.post('/vehicle_groups', pData);
          this.vehicleGroup = {};
          toast('success', this.$t('messages.saved'), 5000);
          this.isModalOpen = false;
          this.$emit('save');
        } catch (error) {
          console.error(error);
        }
        this.loading.save = false;
      }
    },
    prepareData() {
      const { vehicleGroup: V } = this;
      return {
        name: V.name,
        description: V.description,
        display_color: V.display_color.slice(1)
      };
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      if (htmlValidator && !this.vehicleGroup.display_color) {
        toast('warning', this.$t('validations.chooseColor'));
        htmlValidator = false;
      }
      return htmlValidator;
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
#vehicleGroup
  form
    padding-left: 10px
    margin-bottom: 15px
</style>
